import qs from 'qs'
import {SEARCH_RESULT_PAGE, SEARCH_RESULT_PAGE_EN} from 'src/constants/route'
import {LOCALE_EN} from 'src/constants/locale'
import {LocaleType} from 'src/types/utils'
import {getSearchParams} from './property'
import {SearchPropertiesParams, searchValues} from 'src/types/search'

export function getCleanSearchParams(
  searchParams: SearchPropertiesParams,
  initialParams: any = {},
) {
  let finalParams: any = {
    ...initialParams,
  }
  const anySearchParams = searchParams as any

  if (
    searchParams.center_bounds &&
    searchParams.center_bounds.lat &&
    searchParams.center_bounds.lng
  ) {
    finalParams.lat = searchParams.center_bounds.lat.toFixed(7).toString()
    finalParams.lng = searchParams.center_bounds.lng.toFixed(7).toString()
  }

  for (let key in searchValues) {
    const value = searchValues[key]

    if (typeof anySearchParams[key] === 'undefined') {
      continue
    }

    if (anySearchParams[key].toString().length === 0) {
      continue
    }

    finalParams[value] = anySearchParams[key]
  }

  const amenities = searchParams.amenities
  if (amenities && amenities.length > 0) {
    finalParams.amenities = amenities
  }

  return finalParams
}

export function getSearchUrl(locale: LocaleType) {
  const finalParams = getCleanSearchParams(getSearchParams(1))

  const searchPage =
    locale === LOCALE_EN ? SEARCH_RESULT_PAGE_EN : SEARCH_RESULT_PAGE

  const url: string = `${searchPage}?${qs.stringify(finalParams, {
    arrayFormat: 'repeat',
  })}`

  return url
}
