export const popularAmenities: string[] = [
  'spa',
  'lakefront',
  'pet_friendly',
  'pool',
  'fire_pit',
  'indoor_fireplace',
  'air_conditioning',
  'sauna',
  'view',
  'ev_charger',
  'ski-in/ski-out',
  'bbq_grill',
  'wheelchair_accessible',
  'tv',
  'alpine_skiing',
  'golf',
  'snowmobile_trail',
  'by_a_river',
  'corporate_meeting',
  'waterfront',
]
