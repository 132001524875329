import {publicAddDataCollectionApi} from 'src/services/api/dataCollection'
import store from 'src/store'

export function sendSearchDataCollection() {
  const state = store.getState()

  const mapBounds = state.propertySearch.mapBounds
  const mapCenterBounds = state.propertySearch.mapCenterBounds
  const selectedLocation = state.propertySearch.selectedLocation
  const selectedDateRange = state.propertySearch.selectedDateRange
  const guests = state.propertySearch.selectedGuests
  const amenities: string[] = state.propertySearch.selectedAmenities

  //Filters
  const priceFilter = state.propertySearch.priceRangeFilter
  const moreFilters = state.propertySearch.moreFilters

  const data = {
    destination: selectedLocation,
    map_bounds: mapBounds,
    map_center_bounds: mapCenterBounds,
    start: selectedDateRange?.startDate
      ? selectedDateRange.startDate.format('YYYY-MM-DD')
      : null,
    end: selectedDateRange?.endDate
      ? selectedDateRange.endDate.format('YYYY-MM-DD')
      : null,
    adults: guests.adults,
    infants: guests.infants,
    children: guests.children,
    animals: guests.animals,
    amenities: amenities,

    min_price: priceFilter.minPrice !== 0 ? priceFilter.minPrice : null,
    max_price: priceFilter.maxPrice !== 0 ? priceFilter.maxPrice : null,
    internet_type: moreFilters.internetType,
    capacity: moreFilters.capacity !== 0 ? moreFilters.capacity : null,
    beds: moreFilters.beds !== 0 ? moreFilters.beds : null,
    rooms: moreFilters.rooms !== 0 ? moreFilters.rooms : null,
    toilets: moreFilters.toilets !== 0 ? moreFilters.toilets : null,
  }

  publicAddDataCollectionApi('search', data)
}
