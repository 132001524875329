import {Dayjs} from 'src/utils/date'
import {AutocompleteLocationType} from 'src/types/location'

export interface Guests {
  adults: number
  children: number
  infants: number
  animals: number
}

export interface DateRange {
  startDate: Dayjs | null
  endDate: Dayjs | null
}

export type Order = 'asc' | 'desc'

export type MapBounds = {
  north: number
  east: number
  south: number
  west: number
}

export type PriceRange = {minPrice: number; maxPrice: number}

export type SortByFilter =
  | 'distance_low_to_high'
  | 'relevance'
  | 'price_low_to_high'
  | 'price_high_to_low'
  | 'guest_rating'

export interface LocationSearch {
  address: string | null
  autocompleteLocation: AutocompleteLocationType | null
  addressComps: {
    country: string | null
    state: string | null
    city: string | null
    mrc: string | null
    county: string | null
  }
  coordinates: {
    lat: number | null
    lng: number | null
  } | null
  automatic: boolean
}

export interface IMoreFilters {
  capacity: number
  rooms: number
  beds: number
  toilets: number
  internetType: string
}

export interface SearchPropertyPriceData {
  property_id: number
  total12: number
}

export interface SearchPropertiesParams {
  start_date?: string
  end_date?: string
  adults?: number
  children?: number
  infants?: number
  number_of_beds?: number
  maximum_guests?: number
  number_of_rooms_bath?: number
  number_of_rooms?: number
  min_price?: number
  max_price?: number
  order?: Order
  order_by?: string
  amenities?: string[]
  internet_type?: string
  bounds?: {
    north: number
    east: number
    south: number
    west: number
  }
  center_bounds?: {
    lat: number
    lng: number
  }
  zoom?: number
  page: number
  user_id?: number | null
  name?: string | null
  tag_ids?: number[] | null
  status?: string | null
  slug?: string | null //'mc' | 'cr' | 'cr&mc' | null
  address?: string
}

// values to put in url
export const searchValues: {[key: string]: string} = {
  start_date: 's_date',
  end_date: 'e_date',
  adults: 'adults',
  children: 'children',
  infants: 'infants',
  animals: 'animals',
  number_of_beds: 'beds',
  maximum_guests: 'capacity',
  number_of_rooms_bath: 'toilets',
  number_of_rooms: 'rooms',
  min_price: 'min_price',
  max_price: 'max_price',
  order_by: 'order_by',
  amenities: 'amenities',
  internet_type: 'internet',
  zoom: 'zoom',
  page: 'page',
}
