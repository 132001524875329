import {makeStyles} from '@material-ui/core/styles'
import {globalZIndex} from 'src/utils/styles'

interface Props {
  inTopBar?: boolean
  isSmallSize: boolean
}

/*

[data-reach-combobox-popover] {
    border: solid 1px hsla(0, 0%, 0%, 0.25);
    background: hsla(0, 100%, 100%, 0.99);
    font-size: 85%;
}

[data-reach-combobox-list] {
    list-style: none;
    margin: 0;
    padding: 0;
    user-select: none;
}

[data-reach-combobox-option] {
    cursor: pointer;
    margin: 0;
    padding: 0.25rem 0.5rem;
}

[data-reach-combobox-option][aria-selected="true"] {
    background: hsl(211, 10%, 95%);
}

[data-reach-combobox-option]:hover {
    background: hsl(211, 10%, 92%);
}

[data-reach-combobox-option][aria-selected="true"]:hover {
    background: hsl(211, 10%, 90%);
}

[data-suggested-value] {
    font-weight: bold;
}
 */
export const useStyles = makeStyles(({palette, spacing, breakpoints}) => ({
  searchContainer: {
    //// background: palette.common.white,
    // zIndex: globalZIndex.moreAboveBase,
  },
  loader: {
    padding: spacing(1.5),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  locationResultsWrapperGeneral: {
    position: 'absolute',
    zIndex: globalZIndex.aboveBase,
    color: palette.text.primary,
    background: palette.common.white,
  },
  locationResultsWrapper: ({inTopBar}: Props) => ({
    width: '100%',
    marginLeft: inTopBar ? -17 : -57,
    marginTop: 25,
    maxWidth: inTopBar ? 790 : 860,
    borderRadius: 10,
    minWidth: inTopBar ? 790 : 860,
    ...(inTopBar && {zIndex: 2000}),
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    zIndex: globalZIndex.moreAboveBase,
  }),
  locationResultsWrapperMobile: {
    width: '100%',
    maxWidth: 324,
    minWidth: 324,
    border: 'none',
    boxShadow: 'none',
    background: 'none',
    zIndex: globalZIndex.aboveBase,
  },
  locationResultsList: {
    padding: spacing(4, 2),
  },
  locationResultsItem: ({isSmallSize}: Props) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: spacing(3.2 / 8, 15 / 8),
    '& li': {
      '& span': {
        fontWeight: 500,
        fontSize: isSmallSize ? 13 : 14,
        lineHeight: isSmallSize ? '18px' : '24px',
      },
    },
  }),
  locationIconWrapper: ({isSmallSize}: Props) => ({
    flex: '0 0 20px',
    maxWidth: isSmallSize ? 14 : 16,
    marginRight: 0,
    marginTop: isSmallSize ? 9 : 7,
    ...(isSmallSize && {maxHeight: 17}),
  }),
  locationResultsIcon: {
    display: 'inline-block',
    width: '16px',
    margin: spacing(0, 1.25, 0, 0),
  },
  supPopularTitle: {
    display: 'block',
    paddingBottom: spacing(1.5),
    margin: '0 auto',
    marginLeft: spacing(1.5),
    width: '100%',
    textAlign: 'left',
  },
  popularTitle: {
    fontSize: 18,
    fontWeight: 600,
    margin: '0 auto 5px auto',
    marginLeft: spacing(1.5),
    display: 'block',
    width: '100%',
    textAlign: 'left',
  },
  locationResultsIconHover: {
    width: '100%',
    margin: spacing(0, 1.25, 0, 0),
    color: palette.grey[500],
  },
  locationResultText: {
    color: '#525252',
    padding: 0,
    fontWeight: 600,
    '&:hover': {
      background: 'transparent',
    },
  },
  headerSearchOption: {
    fontFamily: 'Inter, Arial, sans-serif',
    '& input::placeholder': {
      fontWeight: 500,
      fontSize: 14,
      color: `${palette.grey[500]} !important`,
    },
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  headerSearchOptionText: {
    //paddingLeft: spacing(1.25),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexDirection: 'row',
    fontSize: '12px !important',
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: 500,
    '&::placeholder': {
      fontSize: 14,
      color: `${palette.grey[600]} !important`,
    },
  },
  locationMobileInput: {
    padding: spacing(1.25, 2),
    width: '100%',
    height: spacing(5.5),
    background: palette.common.white,
    border: `1.5px solid ${palette.primary.main}`,
    borderRadius: spacing(3),
    outline: 'none',
    fontFamily: 'Inter, Arial, sans-serif',
  },
  locationResultsWrapperSM: {
    border: 'none',
    background: 'none',
    top: 'auto',
    left: 'auto',
  },
  LocationIconMobile: {
    maxWidth: 14,
    maxHeight: 17,
  },
  locationLabel: {
    display: 'flex',
  },
}))
