import {PropertySearchStoreState} from 'src/store/reducers/propertySearchReducer'

type FuncType = {
  propertySearch: PropertySearchStoreState
}

export const getSearchData = (state: FuncType) =>
  state.propertySearch.searchData

export const getSelectedProperty = (state: FuncType) =>
  state.propertySearch.selectedProperty

export const getSelectedLocation = (state: FuncType) =>
  state.propertySearch.selectedLocation

export const getCurrentMapZoom = (state: FuncType) => state.propertySearch.zoom

export const getSelectedDateRange = (state: FuncType) =>
  state.propertySearch.selectedDateRange

export const getSelectedGuests = (state: FuncType) =>
  state.propertySearch.selectedGuests

export const getSelectedAmenities = (state: FuncType): string[] =>
  state.propertySearch.selectedAmenities

export const getSelectedSearchPageNo = (state: FuncType) =>
  state.propertySearch.selectedSearchPageNo

//property filters

export const getSortByFilter = (state: FuncType) =>
  state.propertySearch.sortByFilter

export const getMoreFilters = (state: FuncType) =>
  state.propertySearch.moreFilters

export const getPriceRangeFilter = (state: FuncType) =>
  state.propertySearch.priceRangeFilter

export const getMapBoundsFilter = (state: FuncType) =>
  state.propertySearch.mapBounds

export const getMapCenterBoundsFilter = (state: FuncType) =>
  state.propertySearch.mapCenterBounds

export const getIsSearchLoading = (state: FuncType) =>
  state.propertySearch.loading

export const getTabView = (state: FuncType) => state.propertySearch.tabView

export const getErrorCode = (state: FuncType) => state.propertySearch.errorCode

export const getClickedPropertyUid = (state: FuncType) =>
  state.propertySearch.clickedPropertyUid

export const getScrollY = (state: FuncType) => state.propertySearch.scrollY

export const getLastSearchParams = (state: FuncType) =>
  state.propertySearch.lastSearchParams
